* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Antic Slab", sans-serif;
}

body,
html {
  height: 100%;
  overflow: auto;
  background: linear-gradient(to bottom, white, #e6e6e6);
  background: gradient;
}

body {
  animation: dropbody 1s 0.1s ease;
}

header {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  height: 15vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  align-items: center;
}

.logo-container {
  flex: 2;
  justify-content: center;
}

.logo-container > img {
  max-width: 280px;
  height: auto;
  transition: transform 0.4s;
}

.logo-container > img:hover {
  transform: scale(1.1);
}

nav {
  flex: 2;
  width: 100%;
}
.nav-links {
  flex-wrap: no-wrap;
  justify-content: space-evenly;
  list-style: none;
}

.nav-links > li {
  text-align: center;
}

.nav-link {
  /* display: table-cell;
   vertical-align: middle; */
  color: #808090;
  width: 100%;
  height: 100%;
  display: block;
  font-weight: 800;
  text-decoration: none;
  padding: 10px;
  transition: font-size 0.4s, transform 0.4s;
}

#mycurriculum {
  cursor: pointer;
}

.nav-link:hover {
  transform: scale(1.2);
}

.nav-links,
.logo-container {
  display: flex;
  align-items: center;
}

main {
  flex-grow: 1;
}

.presentation {
  flex-direction: row-reverse;
  display: flex;
  width: 60%;
  max-height: 80vh;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.introduction {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  flex-direction: column;
}

.intro-text {
  display: flex;
  flex-direction: column;
  align-items: flex;
}

.intro-text > h1 {
  margin: 0 auto;
  text-align: center;
  font-size: 1.7em;
  background: linear-gradient(to right, #808090, #66bf00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.intro-text > p {
  text-align: center;
  color: #808090;
  font-weight: 600;
  margin-top: 5px;
  font-size: 1.1em;
}

.cover {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.cover > img {
  max-width: 150px;
  max-height: auto;
  border-radius: 20%;
  transition: border-radius 0.4s, transform 1s;
  filter: drop-shadow(0px 5px 3px rgb(66, 34, 34));
  animation: drop 1s 0.7s ease forwards;
  opacity: 0;
}

.cover > img:hover {
  border-radius: 10%;
  transform: scale(1.1);
}

.cta {
  display: flex;
  justify-content: center;
}

.cta > div > a > img {
  display: block;
  margin: 10px;
  max-width: 30px;
  height: auto;
  transition: transform 0.2s;
}

.cta > div > a > img:hover {
  transform: scale(1.2);
}

@keyframes drop {
  0% {
    opacity: 0;
    transform: translateY(400px) translateX(400px) rotateX(0deg) rotateZ(360deg);
  }

  100% {
    opacity: 1;
    transform: translateY(0px) translateX(0) rotateX(360deg) rotateZ(360deg);
  }
}

@keyframes dropbody {
  0% {
    transform: translateY(-500px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media only screen and (max-width: 768px) {
  header {
    width: 99%;
  }
  .presentation {
    width: 99%;
    margin-top: 50px;
  }
  .cover {
    margin-top: 20px;
  }
  .cta {
    margin-top: 20px;
  }
  .cover,
  .introduction .logo-container,
  nav {
    flex-basis: 100%;
  }
}

.icon {
  max-width: 30px;
  height: auto;
  display: inline-block;
  transform: translate(0, 7px);
}

.icon-react {
  max-width: 30px;
  height: auto;
  display: inline-block;
  animation: rotate;
  animation-iteration-count: infinite;
  animation-duration: 2.5s;
  animation-timing-function: linear;
}

.cta-github > a > img {
  animation: rotate-notranslate;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  animation-timing-function: linear;
}

@keyframes rotate-notranslate {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes rotate {
  0% {
    transform: translate(0, 7px) rotateZ(0deg);
  }
  100% {
    transform: translate(0, 7px) rotateZ(360deg);
  }
}

#icon-ionic {
  max-width: 40px;
  height: auto;
  display: inline-block;
  animation: rotate;
  animation-iteration-count: infinite;
  animation-duration: 2.5s;
  animation-timing-function: linear;
}

.contacts > div > a {
  text-decoration: none;
  color: #808090;
}
